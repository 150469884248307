import league from '@b2c/core/app/service/domain/league.js';

(function () {
  'use strict';

  const directive = { name: 'getLeagueToScope' };

  controller.$inject = ['league'];
  function controller(_league) {
    function link(scope, element, attrs) {
      const variable = attrs[directive.name] || 'league';

      scope[variable] = null;

      scope._leagueData = null;

      const subscription = _league
        .onUpdate((data) => {
          scope[variable] = data;
        })
        .subscribe();

      scope.$on('$destroy', () => {
        subscription?.unsubscribe();
      });
    }
    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
