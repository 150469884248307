import league from '@b2c/core/app/service/domain/league.js';
import icon from '@b2c/core/app/service/domain/icon.js';

(function () {
  'use strict';

  const directive = { name: 'historyToScope' };

  factory.$inject = ['system$', 'league', 'icon'];
  function factory(_system$, _league, _icon) {
    function formatDate(date) {
      let month = date.getMonth() + 1;
      if (month < 10) {
        month = `0${month}`;
      }
      return `${date.getDate()}.${month}`;
    }

    function checkLastLeague(date) {
      let d1 = new Date(date);
      let d2 = new Date(Date.now());
      let diff = Math.floor((d2 - d1) / (1000 * 60 * 60 * 24));
      return diff < 7;
    }

    function link(scope, element, attrs) {
      const variable = attrs[directive.name] || 'history';

      _league.requestHistoryData();

      _league.onHistoryUpdate((data) => {
        scope[variable] = data.map((week) => {
          const startDate = new Date(week.start || Date.now());
          const endDate = new Date(week.end || Date.now());
          return {
            ...week,
            startDate: formatDate(startDate),
            endDate: formatDate(endDate),
            lastLeague: checkLastLeague(endDate),
          };
        });
      });
    }
    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, factory);
})();
