import user from "@b2c/core/app/service/domain/user.js";
import popup from "@b2c/core/app/service/domain/popup.js";

(function(){
    'use strict';


    const directive = { name: 'leagueHowPopup' };

    controller.$inject = [ 'user', 'popup' ];

    function controller( _user, _popup ){



        function link( scope, element, attrs ){

            document.querySelector('.popup').classList.add('how-popup');

            let button = document.querySelector('batman-popup-wrapper5').querySelector('.how-btn');
            let link = document.querySelector('.league-how-it-works__link');
            let descs = document.querySelectorAll('.league-how-it-works__step');
            let arrows = document.querySelectorAll('.league-how-it-works__arrow');

            button.addEventListener('click', event => {
                document.querySelector('.popup').classList.remove('how-popup');
                _popup.close();
            });

            let tl = new TimelineMax({delay:.2});
            tl.fromTo(descs[0], .5 ,{opacity:0, x:10},{opacity:1, x:0});
            tl.fromTo(arrows[0], .2 ,{opacity:0, y:-25},{opacity:1, y:0});
            tl.fromTo(descs[1], .5 ,{opacity:0, x:10},{opacity:1, x:0});
            tl.fromTo(arrows[1], .2 ,{opacity:0, y:-25},{opacity:1, y:0});
            tl.fromTo(descs[2], .5 ,{opacity:0, x:10},{opacity:1, x:0});
            tl.fromTo(link, .2 ,{opacity:0, y:25},{opacity:1, y:0});
            tl.fromTo(button, .2 ,{scale:0},{scale:1});

        }
        return {
            restrict: 'A',
            link
        };
    }
    app.directive( directive.name, controller );
})();
