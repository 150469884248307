import system$ from '@b2c/core/app/service/rx/system$.js';
import league from '@b2c/core/app/service/domain/league.js';
import ws$ from '@b2c/core/app/service/rx/ws$.js';
import icon from '@b2c/core/app/service/domain/icon.js';
import wsEvents from '@b2c/core/app/service/configs/ws-events.js';
import user from '@b2c/core/app/service/domain/user.js';
import popup from '@b2c/core/app/service/domain/popup.js';
import tournament from '@b2c/core/app/service/domain/tournament.js';
import { filter } from 'rxjs/operators';
import { pipe } from 'rxjs';

(function () {
  'use strict';

  const directive = { name: 'showLeaguePopupWs' };

  controller.$inject = ['system$', 'league', 'ws$', 'icon', 'tournament', 'wsEvents', 'user', 'popup', '$rootScope'];

  function controller(_system$, _league, _ws$, _icon, _tournament, _wsEvents, _user, _popup, $rootScope) {
    function link(scope) {
      let subscription;
      let icons = [];
      let league = null;

      Promise.all([
        _icon.collection({ category: 'league' }),
        _tournament.fastTournamentList({ strategy: 'league', limit: 100 }),
      ]).then(([iconsRaw, leagueRaw, vipRaw]) => {
        icons = iconsRaw.result;
        league = leagueRaw.result[0];
      });

      function transformPrizeOption(option, icons) {
        const icon = icons.find(({ code }) => code === option.data.code);
        return {
          icon: icon?.logo,
          title: icon?.title,
          ...option,
        };
      }

      _ws$.pipe(
        filter(({ event }) => event === _wsEvents.leagueRewardWinners)
      )
        .subscribe(({ data }) => {
          if (_user.status) {
            if (data[0].data.type == 'instant') {

              let several = false;
              if (data.length > 1) {
                several = true;
              }

              data = data.map((option) => transformPrizeOption(option, icons));

              const rewardsTypes = [];
              const rewardsIds = [];
              data.forEach((element) => {
                rewardsTypes.push(element.rewardType);
                rewardsIds.push(element.rewardId);
              });

              let uniqeRewardsTypes = [...new Set(rewardsTypes)];
              let rewards = [];
              uniqeRewardsTypes.forEach((element) => {
                let rewardAmount = 0;
                data
                  .filter((item) => item.rewardType === element)
                  .forEach((reward) => {
                    rewardAmount += reward.rewardAmount;
                  });
                rewards.push({
                  type: element,
                  rewardAmount: rewardAmount,
                  icon: data.filter((item) => item.rewardType === element)[0].icon,
                });
              });

              const content = {
                leagues: { old: data[data.length - 1].place + 1, new: data[0].place },
                rewards: rewards,
                rewardsIds: rewardsIds,
                several: several,
              };

              _tournament.fastTournamentGetLeaderboard(league.tournamentEventId, { limit: 100 }).then((leaderboard) => {
                content.newPoints = leaderboard.result.currentPlayer.data.totalPoints;
                content.oldPoints = 0;

                for (let i = content.leagues.old; i <= 8; i++) {
                  if (leaderboard.result.leaguePoints.filter((item) => item.league === i).length > 0) {
                    content.oldPoints = leaderboard.result.leaguePoints.filter((item) => item.league === i)[0].points;
                    break;
                  }
                }

                if (document.location.href.indexOf('/play') <= 0) {
                  let opened = true;
                  subscription = _league.onUpdate((e) => {
                    if (e.currentPlayer !== null && opened) {
                      opened = false;
                      _popup.open({ name: 'league-instant-rewards', content: content });
                    }
                  });
                } else {
                  localStorage.setItem('instantRewards', JSON.stringify(content));

                  let subInst = $rootScope.$on('$locationChangeSuccess', function ($event, next, current) {
                    if (next !== current) {
                      const objRewards = JSON.parse(localStorage.getItem('instantRewards'));

                      setTimeout(() => {
                        _popup.open({ name: 'league-instant-rewards', content: objRewards });
                        localStorage.removeItem('instantRewards');
                        subInst();
                      });
                    }
                  });
                }
              });
            }
          }
        });

      scope.$on('$destroy', () => {
        subscription?.unsubscribe();
      });
    }
    return {
      restrict: 'A',
      link,
    };
  }
  app.directive(directive.name, controller);
})();
