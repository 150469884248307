import user from "@b2c/core/app/service/domain/user.js";
import user$ from "@b2c/core/app/service/rx/user$.js";
(function () {

    'use strict';

    const directive = { name: 'scrollSidebar' };

    controller.$inject = [ '$state','user$', 'user' ];

    function controller( $state,_user$, _user ){

        function link( scope, element, attrs ){

            const _scroll = document.querySelector('.sidebar__scroll');
            const _wrap = document.querySelector('.sidebar__scroll__wrap');
            const _border = document.querySelector('.sidebar__scroll__border');

            window.onload = function() {
                checkScroll();
                if ($state.params.lang == 'ja') {
                    document.querySelector('.sidebar__more').addEventListener('click', checkScroll);
                }
            };

            _user$.subscribe( data => {
                checkScroll();
            } );

            checkScroll();

            window.addEventListener("resize", checkScroll);

            scope.$on( '$stateChangeSuccess', function( event, toState, toParams, fromState, fromParams ){
                checkScroll();
            });

            function checkScroll(){
                _scroll.classList.toggle('scrolled', (_scroll.offsetHeight + 1) < _wrap.offsetHeight );
                if(_scroll.offsetHeight + 1 < _wrap.offsetHeight){
                    _border.classList.add('bottom');
                } else {
                    _border.classList.remove('bottom');
                    _border.classList.remove('top');
                }
            }

            _scroll.addEventListener( 'scroll', function(){
                _border.classList.toggle('top', _scroll.getBoundingClientRect().top !== _wrap.getBoundingClientRect().top );
                _border.classList.toggle('bottom', _wrap.offsetHeight - _scroll.offsetHeight !== _scroll.getBoundingClientRect().top - _wrap.getBoundingClientRect().top);
            } );

        }

        return {
            restrict: 'A',
            link
        };
    }

    app.directive( directive.name, controller );

})();
