import $ from "jquery"
import system$ from "@b2c/core/app/service/rx/system$.js";
import user from "@b2c/core/app/service/domain/user.js";
import promo from "@b2c/core/app/service/domain/promo.js";
import tournament from "@b2c/core/app/service/domain/tournament.js";
import achievement from "@b2c/core/app/service/domain/achievement.js";
import achievement$ from "@b2c/core/app/service/rx/achievement$.js";
import bonus from "@b2c/core/app/service/domain/bonus.js";
import shop from "@b2c/core/app/service/domain/shop.js";
import shop$ from "@b2c/core/app/service/rx/shop$.js";
import game from "@b2c/core/app/service/domain/game.js";
import account from "@b2c/core/app/service/domain/account.js";
import { pipe } from "rxjs";
import { filter } from "rxjs/operators";

(function(){

    'use strict';

    const component = { name: 'wazambaDashboard' };

    controller.$inject = [ '$scope', 'promo', 'achievement', "achievement$", "system$", "tournament", "bonus", "user", "shop", "shop$", 'game', 'account'];

    function controller( $scope, _promo, _achievement, _achievement$, _system$, _tournament, _bonus, _user, _shop, _shop$, _game, _account){


        $scope.promos = {};

        $scope.achievements = [];

        $scope.tournament = [];
        $scope.bonus = [];
        $scope.shopExchange = {};
        $scope.shopFs = {};
        $scope.shopMask = {};
        $scope.gameLast = [];
        $scope.gameWin = [];

        let subscription;
        let subscriptionShop;

        function getElementIndex(target) {
            const arr = document.querySelectorAll('.dash-slider__item');
            let el = document.getElementsByTagName(`${target}`)[0];
            return [].indexOf.call(arr, el);
        }
        $(document).ready(function () {
            $('.dash-slider__wrap')
                .on('init', function () {
                }).slick({
                    infinite: false,
                    slidesToShow: 4,
                    fade: false,
                    arrows: true,
                    // dots: false,
                    variableWidth: true,
                    slidesToScroll: 1,
                    nextArrow: "<a class='g-btn g-btn-ico g-btn-orange g-btn-ico-180d slick-arrow slick-next dash-slider__right' >" +
                        "<svg viewBox=\"0 0 36 36\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
                        "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17.0154 15.5221H27.5489C29.4837 15.5221 29.4837 20.4286 27.5489 20.4286H17.0154L19.8637 23.2248C21.2073 24.5437 17.6603 28.0257 16.3167 26.7068L8.36276 18.8986C7.87908 18.4238 7.87908 17.5797 8.36276 17.1048L16.3167 9.29665C17.6603 7.9777 21.2073 11.407 19.8637 12.7259L17.0154 15.5221Z\" />\n" +
                        "</svg>" +
                        "</a>",
                    prevArrow: "<a class='g-btn g-btn-ico g-btn-orange slick-arrow slick-prev dash-slider__left' >" +
                        "<svg viewBox=\"0 0 36 36\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
                        "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17.0154 15.5221H27.5489C29.4837 15.5221 29.4837 20.4286 27.5489 20.4286H17.0154L19.8637 23.2248C21.2073 24.5437 17.6603 28.0257 16.3167 26.7068L8.36276 18.8986C7.87908 18.4238 7.87908 17.5797 8.36276 17.1048L16.3167 9.29665C17.6603 7.9777 21.2073 11.407 19.8637 12.7259L17.0154 15.5221Z\" />\n" +
                        "</svg>" +
                        "</a>",
                    // responsive: [
                    //     {
                    //         breakpoint: 1279,
                    //         settings: {
                    //             slidesToShow: 4,
                    //             slidesToScroll: 1,
                    //             infinite: false,
                    //
                    //             fade: false,
                    //             arrows: true,
                    //             // dots: false,
                    //             variableWidth: true,
                    //
                    //         }
                    //     }
                    // ]
                });
        });

        function getAchievements() {
            _achievement
                .lastEarned({})
                .then( a => {
                    $scope.achievements = a.result.filter( item => item.status === 2);
                    let index =  getElementIndex('batman-achievement-item5');
                    if (!$scope.achievements.length) {$('.dash-slider__wrap').slick('slickRemove', index);}
                })
                .catch( e => { } )
                .finally( () => {} );
        }

        subscription = _achievement$.pipe(filter(data => data.action === 'take')).subscribe( data => {
            getAchievements();
            // $scope.achievements = $scope.achievements.filter( item => item.alias !== data.achievement.alias );
            $('.dash-slider__wrap').slick('reinit');
        } );
        subscriptionShop = _shop$.pipe(filter(data => data.action === 'buy')).subscribe( data => {
            shopExchange();
            shopFs();
            shopMask();
            $('.dash-slider__wrap').slick('reinit');
        } );

        this.$onInit = () => {
            shopExchange();
            shopFs();
            shopMask();
            getAchievements();
        };



        this.$onDestroy = ()=> {
            subscription.unsubscribe();
            subscriptionShop.unsubscribe();
        };

        function sortTournament(a, b) {
            let item1 = moment(a.end, ['DD-MM-YYYY']).format('X');
            let item2 = moment(b.end, ['DD-MM-YYYY']).format('X');

            return item1 - item2;
        }

        _tournament
            .list({})
            .then(a => {
                const activeTournaments = a.result.filter(tournament => tournament.status === 1);
                let userTournament = [];
                let listTournament = [];

                Promise.all(activeTournaments.map(item => _tournament.item({name: item.name})))
                    .then(item =>
                        item
                            .map(item => item.result)
                            .sort(sortTournament)
                            .forEach( item => item.currentUser ? userTournament.push(item.name) : listTournament.push(item.name)));

                $scope.tournament = userTournament[0] ? userTournament[0] : listTournament;

            })
            .catch(e => {})
            .finally(() => {});

        _bonus
            .list({})
            .then(a => {
                a.result.filter( function (item) {
                    if(item.type === "freespin") {
                        item.status === 'active' ? $scope.bonus.unshift(item) : $scope.bonus.push(item);
                    }
                } );
                if (!$scope.bonus.length) {
                    $('.dash-slider__wrap').slick('slickRemove', getElementIndex('batman-bonus-spin-item3'));
                }
            })
            .catch(e => {})
            .finally(() => {});

        function shopExchange() {
            _shop
                .list({category: 'exchange'})
                .then(a => {
                    let result = a.result[0];
                    a.result.forEach(function
                        (item) {
                        if(_user.profile.coins >= item.price) {
                            result = item;
                        }
                    });
                    $scope.shopExchange = result;
                })
                .catch(e => {
                    console.error(e);
                })
                .finally(() => {});

        }

        function shopFs() {
            _shop
                .list({category: 'freespin'})
                .then(a => {
                    let arrShop = a.result.sort(function (a, b){
                        return a.price - b.price;
                    });
                    let result = arrShop[0];

                    arrShop.forEach(function (item) {
                        if(_user.profile.coins >= item.price) {
                            result = item;
                        }
                        $scope.shopFs = result;
                    });
                    $scope.shopFs = result;
                    // let arr = arrShop.filter(item => item.price === $scope.shopFs.price);
                    // $scope.shopFs = arr[Math.floor((Math.random() * arr.length) + 1)];
                    // console.log("??????/",$scope.shopFs);
                    // console.log("MIN2", Math.min.apply(Math, arrShop));
                    // let x = 20;
                    //
                    // arrShop.forEach(function (item) {
                    //
                    //     if(_user.profile.coins - item.price < x && _user.profile.coins - item.price  > 0) {
                    //         x = _user.profile.coins - item.price;
                    //         result = item;
                    //     } else if (x < _user.profile.coins - item.price){
                    //         x = _user.profile.coins - item.price;
                    //     }
                    //     $scope.shopFs = result;
                    // });

                    $scope.shopFs = result;
                })
                .catch(e => {
                    console.error(e);
                })
                .finally(() => {});

        }

        function shopMask() {
            _shop
                .list({category: 'mask'})
                .then(a => {
                    let result = a.result[0];
                    a.result.forEach(function
                        (item) {
                        if(_user.profile.coins >= item.price) {
                            result = item;
                        }
                    });
                    $scope.shopMask = result;
                })
                .catch(e => {
                    console.error(e);
                })
                .finally(() => {});

        }

        _promo
            .list({category: 'dashboard_bonus'})
            .then( a => {
                $scope.promos = _promo.result.find(item => item.alias === "welcome-bonus");
            })
            .catch( e => {} )
            .finally( () => {} );

        _game
            .recentlyPlayed()
            .then( a => {
                $scope.gameLast = a.result;
                if(!$scope.gameLast.length) {
                    $('.dash-slider__wrap').slick('slickRemove', getElementIndex('batman-recently-played-list1'));
                }
            })
            .catch(e => {} )
            .finally( () => {});

        _account
            .win()
            .then(a=>{
                $scope.gameWin = a.result;
                if(!$scope.gameWin[0].amount) {
                    $('.dash-slider__wrap').slick('slickRemove', getElementIndex('batman-user-win1'));
                }
            })
            .catch(e => {} )
            .finally( () => {});
    }

    app.component( component.name, {
        controller,
        templateUrl: app.getTU( component.name )
    });


})();
