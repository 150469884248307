const _temp0 = require("./directive/add-class-to-scroll.js");
const _temp1 = require("./directive/add-focus-on-click.js");
const _temp2 = require("./directive/back-to.js");
const _temp3 = require("./directive/change-currency-icon-position.js");
const _temp4 = require("./directive/countdown.js");
const _temp5 = require("./directive/custom-rtl-scroll-game-category.js");
const _temp6 = require("./directive/fontClassFromCurrency.js");
const _temp7 = require("./directive/get-completed-achievments.js");
const _temp8 = require("./directive/get-league-status-to-scope.js");
const _temp9 = require("./directive/get-league-to-scope.js");
const _temp10 = require("./directive/get-path-to-scope.js");
const _temp11 = require("./directive/get-url-to-scope.js");
const _temp12 = require("./directive/history-to-scope.js");
const _temp13 = require("./directive/leaderboard.js");
const _temp14 = require("./directive/league-how-popup.js");
const _temp15 = require("./directive/league-meter.js");
const _temp16 = require("./directive/league-popup.js");
const _temp17 = require("./directive/neon-payment-btn.js");
const _temp18 = require("./directive/open-league-faq.js");
const _temp19 = require("./directive/redirect-fi.js");
const _temp20 = require("./directive/scroll-sidebar.js");
const _temp21 = require("./directive/show-league-popup-ws.js");
const _temp22 = require("./directive/slick-game-slider.js");
const _temp23 = require("./directive/timestamp.js");
const _temp24 = require("./directive/wazamba-bonus-item1.js");
const _temp25 = require("./directive/wazamba-burger-analytics.js");
const _temp26 = require("./directive/wazamba-check-tournaments-is-empty.js");
const _temp27 = require("./directive/wazamba-dashboard.js");
const _temp28 = require("./directive/wazamba-go-back-game-page.js");
const _temp29 = require("./directive/wazamba-hide-sidebar.js");
const _temp30 = require("./directive/wazamba-registration-promocode.js");
const _temp31 = require("./directive/wazamba-remembered-user.js");
const _temp32 = require("./directive/wazamba-scroll-and-open-on-load.js");
const _temp33 = require("./directive/wazamba-scroll-into-view.js");
const _temp34 = require("./directive/wazamba-shop-confirmation-popup1.js");
const _temp35 = require("./directive/wazamba-smooth-scroll-on-click.js");
const _temp36 = require("./directive/animation/authorization-btn.js");
const _temp37 = require("./directive/animation/wazamba-first-screen-scroll.js");

module.exports = {
  "add-class-to-scroll.js": _temp0,
  "add-focus-on-click.js": _temp1,
  "back-to.js": _temp2,
  "change-currency-icon-position.js": _temp3,
  "countdown.js": _temp4,
  "custom-rtl-scroll-game-category.js": _temp5,
  "fontClassFromCurrency.js": _temp6,
  "get-completed-achievments.js": _temp7,
  "get-league-status-to-scope.js": _temp8,
  "get-league-to-scope.js": _temp9,
  "get-path-to-scope.js": _temp10,
  "get-url-to-scope.js": _temp11,
  "history-to-scope.js": _temp12,
  "leaderboard.js": _temp13,
  "league-how-popup.js": _temp14,
  "league-meter.js": _temp15,
  "league-popup.js": _temp16,
  "neon-payment-btn.js": _temp17,
  "open-league-faq.js": _temp18,
  "redirect-fi.js": _temp19,
  "scroll-sidebar.js": _temp20,
  "show-league-popup-ws.js": _temp21,
  "slick-game-slider.js": _temp22,
  "timestamp.js": _temp23,
  "wazamba-bonus-item1.js": _temp24,
  "wazamba-burger-analytics.js": _temp25,
  "wazamba-check-tournaments-is-empty.js": _temp26,
  "wazamba-dashboard.js": _temp27,
  "wazamba-go-back-game-page.js": _temp28,
  "wazamba-hide-sidebar.js": _temp29,
  "wazamba-registration-promocode.js": _temp30,
  "wazamba-remembered-user.js": _temp31,
  "wazamba-scroll-and-open-on-load.js": _temp32,
  "wazamba-scroll-into-view.js": _temp33,
  "wazamba-shop-confirmation-popup1.js": _temp34,
  "wazamba-smooth-scroll-on-click.js": _temp35,
  "animation":   {
    "authorization-btn.js": _temp36,
    "wazamba-first-screen-scroll.js": _temp37
  }
}