import remembered from "@b2c/core/app/service/domain/remembered.js";

(function () {

  'use strict';

  const directive = {name: 'wazambaRememberedUser'};

  controller.$inject = ['remembered'];
  function controller(_remembered) {
    function link(scope) {
      scope.rememberedLogin = _remembered.login;
    }

    return {
      restrict: 'A',
      link
    };
  }

  app.directive(directive.name, controller);
})();
