import user from "@b2c/core/app/service/domain/user.js";
import popup from "@b2c/core/app/service/domain/popup.js";

(function () {

    'use strict';

    const directive = { name: 'neonPaymentBtn' };

    controller.$inject = [ 'user' , 'popup' ];

    function controller( _user , _popup ){

        function link( scope, element, attrs ){


            element[0].addEventListener( 'click' , ()=>{

                if( _user.status){
                    const type = scope.type === 'deposit' ? 'deposit' : 'withdrawal';
                    const step = scope.$parent.$parent.country === _user.profile.country && scope.$parent.$parent.currency === _user.profile.currency ? 'form' : 'payments';

                    _popup.open({
                        name:'cashbox',
                        content : {
                            tab : type,
                            step,
                            paySystem : scope.item
                        }
                    });



                }else{
                    _popup.open({ name: _user.profile.login ? 'login' : 'registration' });
                }

            });

        }

        return {
            restrict: 'A',
            link
        };
    }

    app.directive( directive.name, controller );

})();
