import config from "@b2c/core/app/service/configs/config.js";

(function(){
    'use strict';

    const lib = {
        '1':{avatar: '0', mask: '1'},
        '2':{avatar: '1', mask: '2'},
        '3':{avatar: '2', mask: '3'},
        '4':{avatar: '0', mask: '4'},
        '5':{avatar: '1', mask: '5'},
        '6':{avatar: '2', mask: '6'},
        '7':{avatar: '0', mask: '7'},
        '8':{avatar: '1', mask: '8'},
        '9':{avatar: '2', mask: '9'},
        '0':{avatar: '0', mask: '10'},
        'a':{avatar: '1', mask: '11'},
        'b':{avatar: '2', mask: '12'},
        'c':{avatar: '0', mask: '13'},
        'd':{avatar: '1', mask: '14'},
        'e':{avatar: '2', mask: '15'},
        'f':{avatar: '0', mask: '16'},
        'g':{avatar: '1', mask: '17'},
        'h':{avatar: '2', mask: '1'},
        'i':{avatar: '0', mask: '2'},
        'j':{avatar: '1', mask: '3'},
        'k':{avatar: '2', mask: '4'},
        'l':{avatar: '0', mask: '5'},
        'm':{avatar: '1', mask: '6'},
        'n':{avatar: '2', mask: '7'},
        'o':{avatar: '0', mask: '8'},
        'p':{avatar: '1', mask: '9'},
        'q':{avatar: '2', mask: '10'},
        'r':{avatar: '0', mask: '11'},
        's':{avatar: '1', mask: '12'},
        't':{avatar: '2', mask: '13'},
        'u':{avatar: '0', mask: '14'},
        'v':{avatar: '1', mask: '15'},
        'w':{avatar: '2', mask: '16'},
        'x':{avatar: '0', mask: '17'},
        'y':{avatar: '1', mask: '1'},
        'z':{avatar: '2', mask: '2'}
    };

    const directive = { name: 'leaderboard' };

    controller.$inject = [ 'config' ];

    function controller( _config ){

        const avatars = ['0', '1', '2'];
        const resource = `${_config.cdn}/wazamba`;

        function random(max){
            return Math.floor((Math.random() * max));
        }

        function maskFromLib(login){
            const l = typeof login === 'string' && login.length ? login.toLowerCase() : 'Q';
            return lib[l[0]] ? lib[l[0]].mask : random(12)
        }

        function avatarFromLib(login = 'Q'){
            const l = typeof login === 'string' && login.length ? login.toLowerCase() : 'Q';
            return lib[l[0]] ? lib[l[0]].avatar : random(3)
        }



        function link( scope, element, attrs ){

            scope.leaderboard = [];

            const stopObserving = attrs.$observe( 'leaderboard', newVal => {

                const tournament = scope.$eval( newVal );

                if(tournament.id){

                    stopObserving();

                    scope.leaderboard = tournament.places_tournament.map( ( place, index ) => {

                        const player = tournament.players[index] || {};

                        if(avatars.includes(player.avatar) === false) {
                            player.avatar = avatarFromLib(player.login);
                            player.mask = maskFromLib(player.login);
                        }

                        const file = player.login ? `/hero2/${player.avatar}_${player.mask}.png` : '/wazamba-v01/tournaments/tournament-empty-hero.png';

                        player.picture = `${resource}${file}`;

                        return {
                            place: index,
                            prize: place,
                            player
                        };

                    });
                }


            } );




        }
        return {
            restrict: 'A',
            link
        };
    }
    app.directive( directive.name, controller );
})();
