// - This popup redirects user after successful purchasing a bonus to
// - 'successAlias' from scope, if it exists, or to
// - an alias, that is stated in config.mjs "bonusSuccessAlias" object

import contentBuffer from '@b2c/core/app/service/domain/content-buffer.js';
import shop$ from '@b2c/core/app/service/rx/shop$.js';
import shop from '@b2c/core/app/service/domain/shop.js';
import popup from '@b2c/core/app/service/domain/popup.js';
import state from '@b2c/core/app/service/domain/state.js';
(function () {
  controller.$inject = ['$scope', 'contentBuffer', 'shop$', 'shop', 'popup', 'state'];

  function controller($scope, _contentBuffer, _shop$, _shop, _popup, _state) {
    $scope.item = _contentBuffer.read('popup');
    $scope.preloader = false;
    $scope.successAlias = '';

    $scope.buy = (activateAfterBuy) => {
      $scope.preloader = true;
      let config = { id: $scope.item.id };
      if (activateAfterBuy) {
        Object.assign(config, { activateAfterBuy });
      }
      _shop
        .buy(config)
        .then(
          (a) => {
            _shop$.next({ action: 'buy', goods: $scope.item });
            _popup.close();
            const type = $scope.item.type;
            if (activateAfterBuy || type === 'cash_crab') {
              if ($scope.successAlias) {
                _state.goto($scope.successAlias);
              } else if (type === 'freespin') {
                _state.goto(`${$_CONFIG.bonusSuccessAlias[type]}`,{name: `${$scope.item.game.alias}`, mode: 'real'});
              } else {
                if (type === 'cash_crab') {
                  _state.goto('bonus_crab', { name: 'cash-crab' });
                } else {
                  _state.goto($_CONFIG.bonusSuccessAlias[type]);
                }
              }
            }
          },
          (a) => {}
        )
        .finally(() => {
          $scope.preloader = false;
        });
    };

    this.$onChanges = () => {
      $scope.successAlias = this.successAlias;
    };
  }

  new Controller('shopConfirmationPopup', controller, {
    successAlias: '<',
  });
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`wazambaShopConfirmationPopup${i}`, 'shopConfirmationPopup');
  });
})();
