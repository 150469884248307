import state from '@b2c/core/app/service/domain/state.js';

(function () {
  'use strict';

  const directive = { name: 'wazambaGoBackGamePage' };

  controller.$inject = ['$state', 'state'];

  function controller($state, _state) {
    function link($scope) {
      const getPreviousPage = () => {
        // debugger;
        if(_state.history.length <= 1) return;
        const prevAlias = _state.history[_state.history.length - 2];
        if(prevAlias.includes('app.root.game') || prevAlias.includes('app.root.claw_games')) return;
        return {
          alias: prevAlias,
          params: _state.previous.params,
        }
      }

      let previousPage = getPreviousPage();

      $scope.goBack = ()=> {
        // debugger;
        if( !previousPage ) _state.goto('home');
        else $state.go(previousPage.alias, previousPage.params);
      };
    }

    return {
      restrict: 'A',
      link: link,
    };
  }

  app.directive(directive.name, controller);
})();
